/* .react-grid-item:not(.react-grid-placeholder) { */
/* background: #ccc; */
/* border: 1px solid black; */
/* &:hover {
    outline: 1px solid black;
  } */
/* } */

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}
.react-grid-item.react-grid-placeholder {
  background-color: #90caf9;
}
