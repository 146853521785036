.tooltip {
  background-color: rgba(104, 108, 155, 0.8);
  padding: 10px;
}

/* .graphContainer:hover {
  /* background: rgba(220, 220, 220, 1); */

/* 
.align-left {
  float: left;
}

.align-right {
  float: right;
} */
