.reporting-cron {
  /* flex: 1; */
  width: 100%;
  z-index: 10000000;
}
.reporting-cron-field > span {
  font-weight: normal;
}
.reporting-cron-error .reporting-cron-field > span {
  color: #ff4d4f;
}
.reporting--cron .reporting-cron-select > div:first-child {
  border-radius: 3px;
}
.reporting-cron-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #dadada;
}
.reporting-cron-clear-button {
  border-radius: 3px;
}
