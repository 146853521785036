.editDashboardButton {
  position: fixed;
  top: 20px;
  z-index: 1;
}

.dashboardSubComponentItem {
  display: flex;
  margin: 5px 5px 0 0;
}

.dashboardSubComponentItemDroppable {
  composes: dashboardSubComponentItem;
  width: 100%;
  padding-top: 1px;
}

.dashboardSubComponentItemIsOver {
  composes: dashboardSubComponentItemDroppable;
  padding-top: 0px;
  border-top: 1px solid blue !important;
}

.dashboardSubComponentItemUndraggable {
  width: 100%;
  /* border-top: 1px solid black; */
  padding-left: 3px;
  /* padding-top: 1px; */
}
.dashboardSubComponentItemDraggable {
  width: 100%;
  /* border-top: 1px solid black; */
  padding-left: 3px;
  /* padding-top: 1px; */
}

.dashboardSubComponentItemDraggable:hover {
  box-shadow: 5px 5px 5px 5px #888888;
}

.dashboardSubComponentItemIsDragging {
  composes: dashboardSubComponentItemDraggable;
  opacity: 0.5;
  padding-left: 0px;

  border-left: 3px solid violet;
  /* box-shadow: 5px 10px #888888!important; */
}

.RemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none !important;
}

.Search {
  overflow-y: auto;
  max-height: 500px;
  z-index: 10000;
}

.Header {
  text-align: center;
}

.Assets {
  text-align: left;
}

.Config {
  margin-top: 100px;
}

.Calendar {
  z-index: 10;
}

.node circle {
  fill: #999;
}

.node text {
  font: 10px sans-serif;
}

.node--internal circle {
  fill: #555;
}

.node--internal text {
  text-shadow:
    0 1px 0 #fff,
    0 -1px 0 #fff,
    1px 0 0 #fff,
    -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1.5px;
}

.SidebarButton {
  position: fixed;
  /* backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  right: 10px; */
  right: 10px;
  top: 50px;
  z-index: 500;
}

.sidebar {
  /* position: fixed; */
  margin-top: 100px;
}

.searchButton {
  position: fixed;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  right: 10px;
  top: 100px;
  z-index: 100;
}
