.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.react-flow__node-initial {
  font-size: 12px;
  background: rgb(163, 207, 163);
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.react-flow__node-valuation {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-inputType {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
  /* margin: 10px; */
}

.react-flow__node-distribution {
  font-size: 12px;
  background: #987;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-transfer {
  font-size: 12px;
  background: #aaa;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-stockPrice {
  font-size: 12px;
  background: #999;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-variable {
  font-size: 12px;
  background: #999;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
